<template>


    <section>
        <b-overlay :show="loading"
                   :variant="variant"
                   :opacity="opacity"
                   :blur="blur"
                   spinner-variant="primary"
                   spinner-type="grow"
                   spinner-large
                   class="loaderPreview"
                   rounded="sm">

            <b-row v-if="meta.breadcrumb || meta.pageTitle"
                   class="content-header">

                <b-col class="content-header-left mb-2"
                       cols="12"
                       md="9">
                    <b-row class="breadcrumbs-top">
                        <b-col cols="12">
                            <h2 class="content-header-title float-left pr-1 mb-0">
                                {{ meta.pageTitle }}
                            </h2>
                            <div class="breadcrumb-wrapper">
                                <b-breadcrumb>
                                    <b-breadcrumb-item to="/">
                                        <feather-icon icon="HomeIcon"
                                                      size="16"
                                                      class="align-text-top" />
                                    </b-breadcrumb-item>
                                    <b-breadcrumb-item key="Projects"
                                                       to="/projects">
                                        Projects
                                    </b-breadcrumb-item>

                                    <b-breadcrumb-item v-if="FunnelStepInfo"
                                                       :key="FunnelStepInfo.ProjectName"
                                                       :to="'/projectdetail/'+FunnelStepInfo.UserFunnelId">
                                        {{FunnelStepInfo.ProjectName}}
                                    </b-breadcrumb-item>

                                    <b-breadcrumb-item v-if="FunnelStepInfo"
                                                       :key="FunnelStepInfo.Name"
                                                       :to="'/projectdetail/'+FunnelStepInfo.UserFunnelId">
                                        {{FunnelStepInfo.Name}}
                                    </b-breadcrumb-item>

                                    <b-breadcrumb-item v-for="item in meta.breadcrumb"
                                                       :key="item.text"
                                                       :active="item.active"
                                                       :to="item.to">
                                        {{ item.text }}
                                    </b-breadcrumb-item>
                                </b-breadcrumb>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>

                <b-col v-if="FunnelStepInfo"
                       class="content-header-right text-md-right d-md-block d-none mb-1"
                       md="3"
                       cols="12">


                    <b-button :to="'/projectdetail/'+FunnelStepInfo.UserFunnelId"
                              title="Project Detail"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              v-b-tooltip.hover.v-primary
                              variant="flat-info"
                              class="btn-icon mr-1">
                        <feather-icon icon="ArrowLeftCircleIcon" /> Exit
                    </b-button>
                </b-col>
            </b-row>

            <b-row class="match-height">
                <b-col lg="12">
                    <iframe :src="WebAPIURL +'/Account/AutoLogin?loginemail=' +CurrUserEmail +'&code=' +AutoLoginKey +'&Id=' +veriationId"
                            @load="load"
                            frameborder="0"></iframe>
                </b-col>
            </b-row>
        </b-overlay>
    </section>
</template>



<script>
    import BCardCode from "@core/components/b-card-code";

    import {
        BCard,
        BCardHeader,
        BCardBody,
        BCardImg,
        BRow,
        BCol,
        BCardText,
        BLink,
        BButton,
        BModal,
        VBModal,
        BFormInput,
        BForm,
        BFormRow,
        BFormValidFeedback,
        BFormInvalidFeedback,
        BFormGroup,
        BTabs,
        BTab,
        BOverlay,
        BBreadcrumb, BBreadcrumbItem,
        BImg,
        VBTooltip
    } from "bootstrap-vue";
    import { $themeConfig } from "@themeConfig";
    import Vue from "vue";
    import jwt from "@/auth/jwt/useJwt";
    import Ripple from "vue-ripple-directive";

    //import axios from 'axios'
    //import qs from "qs"
    /*import { codeTypes } from './code'*/

    export default {
        metaInfo: {
            title: 'Landingly | Edit Project'
        },
        components: {
            BCardCode,
            BCardText,
            BCard,
            BCardHeader,
            BRow,
            BCol,
            BCardBody,
            BCardImg,
            BLink,
            BImg,
            BModal,
            VBModal,
            BButton,
            BFormInput,
            BForm,
            BFormRow,
            BFormValidFeedback,
            BFormInvalidFeedback,
            BFormGroup,
            BTabs,
            BTab,
            BOverlay,
            menuCollapsed: $themeConfig.layout.menu.isCollapsed,
            menuHidden: $themeConfig.layout.menu.hidden,
            navbarType: $themeConfig.layout.navbar.type,
            footerType: $themeConfig.layout.footer.type,
            BBreadcrumb, BBreadcrumbItem,
        },
        setup() {
            // App Name
            const { appName, appLogoImage } = $themeConfig.app
            return {
                appName,
                appLogoImage,
            }
        },

        data() {
            return {
                loading: true,
                variant: "light",
                opacity: 0.85,
                blur: "2px",

                meta: {
                    pageTitle: 'Landingly',
                    breadcrumb: [

                        {
                            text: 'Edit Project',
                            active: true
                        },
                    ],
                },

                FunnelStepInfo: null,
                projects: null,
                error: null,
                CurrUser: JSON.parse(localStorage.getItem("User_Name")),
                CurrUserEmail: JSON.parse(localStorage.getItem("email")),
                veriationId: this.$route.params.id,
                WebAPIURL: $themeConfig.app.apiURL,
                AutoLoginKey: $themeConfig.app.AutoLoginKey,
                iframe: {
                    loaded: false,
                },
            };
        },
        directives: {
            "b-modal": VBModal,
            'b-tooltip': VBTooltip,
            Ripple,
        },
        methods: {
            load: function () {
                this.iframe.loaded = true;
                this.loading = false;

            },

            LoadCheckoutPageTemplates: async function (e) {
                //this.loading = true;
                var self = this;
                this.FunnelStepInfo = null;

                const access_token = await jwt.getToken();
                var axios = require("axios");
                var qs = require("qs");

                var data = qs.stringify({
                    VarId: this.veriationId
                });
                var config = {
                    method: "get",
                    url: "" + this.WebAPIURL + "/api/projects/GetVariationFunnelInfo?" + data,
                    headers: {
                        Authorization: "bearer " + access_token,
                    },
                    data: data,
                };

                axios(config)
                    .then(function (response) {
                        self.FunnelStepInfo = response.data;
                        //self.loading = false;
                    })
                    .catch(function (error) {
                        // console.log(error);
                        //self.loading = false;
                    });
            },
        },
        /*
        created() {
            // Set `appConfig` when page/SFC is created
            this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
        },
        destroyed() {
            // Restore the state value of `appConfig` when page/SFC is destroyed
            this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', this.menuCollapsed)
        },
*/
        created() {
            // var subDomain = jwt.getSubDomain();
            //if (subDomain == null || subDomain == "" || subDomain == 'undefined') {
            //    //this.$router.replace('/setupuseraccount')
            //    this.$router.replace('/login')
            //}
            //else {
            this.LoadCheckoutPageTemplates();
            // Set `appConfig` when page/SFC is created
            this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
            this.$store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: 'hidden' });
            this.$store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: 'hidden' });
            //}
        },
        beforeCreate() {
            
        },
        destroyed() {
            // Restore the state value of `appConfig` when page/SFC is destroyed
            this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden);
            this.$store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: this.navbarType });
            this.$store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: this.footerType });
        },

        //destroyed() {
        //document.querySelector('a[href*="/projects"]').parentNode.classList.remove("active");
        //},
        mounted() {
            //document.querySelector('a[href*="/projects"]').parentNode.classList.add("active");
        },
    };
</script>
<style>
    iframe {
        height: calc(100vh - 100px) !important;
        width: 100%;
    }
</style>